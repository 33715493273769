import React, { useState, useEffect } from 'react';
import Acoustic from '../Icons/Acoustic';
import Bassist from '../Icons/Bassist';
import Drummer from '../Icons/Drummer';
import Guitar from '../Icons/Guitar';
import Keys from '../Icons/Keys';
import Organist from '../Icons/Organist';
import Events from '../Icons/Events';
import Worship from '../Icons/Worship';
import Tech from '../Icons/Tech';
import Vocalist from '../Icons/Vocalist';
import BookService from './BookService';
import css from './ListingServiceOffered.module.css';
import ListServiceStyle from './ListServiceStyle';
import SelectDate from './SelectDate';
import SelectService from './SelectService';
import Modal from '@material-ui/core/Modal';
import BookingWizard from './BookingWizard';
import moment from 'moment';
import { getMonthStartInTimeZone } from '../../util/dates';
import { getAvailableStartTimes, getTimeSlots, getMonthlyTimeSlots } from '../../util/time';
import EstimatedBreakdownMaybe from '../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
import { RWebShare } from 'react-web-share';
import Heart from '../Heart/Heart';
import { addRemoveFavorite } from '../../util/api';

let serviceOfferedObj = {
  acoustic_guitar: <Acoustic />,
  bassist: <Bassist />,
  drummer: <Drummer />,
  electric_guitar: <Guitar />,
  organist: <Organist />,
  pianist: <Keys />,
  special_events: <Events />,
  tech: <Tech />,
  vocalist: <Vocalist />,
  worship_leader: <Worship />,
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ListingServiceOffered = props => {
  let description_church_data = JSON.parse(localStorage.getItem(props.currentUser?.id.uuid));

  const url = window.location.href;
  console.log('urll',url)
  const urlObj = url ? new URL(url) : '';

  // const yogaStyles = urlObj?.searchParams?.get('referrer');

  const serviceNameSearched = urlObj?.searchParams?.get('referrer');
  const checkIfReferrer = serviceNameSearched === 'undefined' ? undefined : serviceNameSearched;
  // const yogaStyles = urlObj?.searchParams?.get('pub_yogaStyles');
console.log('serviceNameSearched',{serviceNameSearched,serviceProp:props?.serviceOffered})

  const [bookingState, setBookingState] = useState({
    rehericaltime: false,
    selectedService: checkIfReferrer ?? props?.serviceOffered?.at(0) ?? '',
    bookingDatesTimes: [],
    description_church: description_church_data?.description_church ?? '',
    description: description_church_data?.description ?? '',
    address: description_church_data?.address ?? '',
    services: description_church_data?.services ?? '',
    city: description_church_data?.city ?? '',
    state: description_church_data?.state ?? '',
    zip: description_church_data?.zip ?? '',
  });
  const [requestBook, setRequestBook] = useState(false);
  const [selectDate, setSelectDate] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isFavorite, setFavorite] = useState(props.isFav);
  const selectedServicePrice = Number(props?.serviceWithPrice[bookingState?.selectedService]);
  const price = selectedServicePrice || props?.currentListing?.attributes?.price?.amount / 100 || 0;
  const timeZone =
    (props?.currentListing?.attributes?.availabilityPlan &&
      props?.currentListing?.attributes?.availabilityPlan.timezone) ||
    moment.tz.guess();

  const bookingStartDate =
    bookingState?.selectedDate?.bookingStartDate[0] || bookingState?.selectedDate?.bookingStartDate;
  const bookingEndDate =
    bookingState?.selectedDate?.bookingEndDate[0] || bookingState?.selectedDate?.bookingEndDate;
  const bookingStartTime = bookingState?.selectedDate?.bookingStartTime;
  const bookingEndTime = bookingState?.selectedDate?.bookingEndTime;
  const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(new Date(), timeZone));
  console.log('bookingStartTime,bookingEndTime', {
    bookingStartDate,
    bookingEndDate,
    bookingStartTime,
    bookingEndTime,
  });
  useEffect(() => {
    props.onFetchTimeSlots(props.currentListing.id, bookingStartDate, bookingEndDate, timeZone);
    // bookingStartDate && bookingEndDate && bookingStartTime && bookingEndTime
    if (bookingState?.bookingDatesTimes.length) {
      console.log('useEffect', {
        startDate: bookingState?.bookingDatesTimes.at(0)?.bookingStartDate,
        endDate: bookingState?.bookingDatesTimes.at(0)?.bookingEndDate,
        selectedService: bookingState.selectedService,
        ...bookingState,
      });
      props.onFetchTransactionLineItems({
        bookingData: {
          startDate: bookingState?.bookingDatesTimes.at(0)?.bookingStartDate,
          endDate: bookingState?.bookingDatesTimes.at(0)?.bookingEndDate,
          selectedService: bookingState.selectedService,
          ...bookingState,
        },
        listingId: props.currentListing.id,
        isOwnListing: props.isOwnListing,
        form: 'first',
      });
    }
  }, [bookingState?.bookingDatesTimes]);

  const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
    props.monthlyTimeSlots,
    currentMonth,
    timeZone
  );

  const timeSlotsOnSelectedDate = getTimeSlots(
    timeSlotsOnSelectedMonth,
    bookingStartDate,
    timeZone
  );

  let start =
    timeSlotsOnSelectedDate && timeSlotsOnSelectedDate[0]
      ? timeSlotsOnSelectedDate[0].attributes.start
      : null;

  // console.log('availableStartTimes', {
  //   monthlyTimeSlots: props.monthlyTimeSlots,
  //   timeSlotsOnSelectedMonth,
  //   timeSlotsOnSelectedMonth,
  //   start,
  // });

  const availableStartTimes = getAvailableStartTimes(
    props.intl,
    timeZone,
    start,
    timeSlotsOnSelectedDate
  );

  function convertTimeToNumber(time) {
    const [hourStr, minuteStr, ampm] = time?.split(/:| /);

    const hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);

    let numericTime;

    if (ampm === 'AM') {
      if (hour === 12) {
        numericTime = 0;
      } else {
        numericTime = hour + minute / 60;
      }
    } else {
      if (hour === 12) {
        numericTime = 12;
      } else {
        numericTime = hour + 12 + minute / 60;
      }
    }

    return numericTime;
  }

  const getModifiedDateTime = dateTime => {
    let startTimeInt = parseInt(dateTime.bookingStartStr);
    let endTimeInt = parseInt(dateTime.bookingEndStr);

    const startHours = convertTimeToNumber(dateTime.bookingStartStr);
    const endHours = convertTimeToNumber(dateTime.bookingEndStr);
    const bookingStartDate = moment(dateTime.bookingStartDate).add(startHours, 'hours')['_d'];
    const bookingEndDate = moment(dateTime.bookingEndDate).add(endHours, 'hours')['_d'];

    let startTime = moment(dateTime.bookingStartStr, 'HH:mm:ss a');
    let endTime = moment(dateTime.bookingEndStr, 'HH:mm:ss a');
    let duration = moment.duration(endTime.diff(startTime));
    let hours = parseInt(duration.asHours());
    let minutes = parseInt(duration.asMinutes()) % 60;
    let calculatedHours = 0;

    if (minutes === 60) {
      calculatedHours = hours + 1;
    } else if (minutes === 30) {
      calculatedHours = hours + 0.5;
    } else {
      calculatedHours = hours;
    }

    return { bookingStartDate, bookingEndDate, calculatedHours, minutes };
  };

  const handleSubmit = () => {
    const {
      selectedDate: {
        bookingEndDate,
        bookingStartDate,
        bookingStartTime,
        bookingEndTime,
        totalSelectedDate,
        startHours,
        endHours,
        selectedDatesTimes,
      },
      rehericaltime,
      other_details,
      city,
      state,
      description_church,
      description,
      services,
      rehericalStartTime,
      rehericalEndTime,
      reherical_date,
      bookingDatesTimes,
    } = bookingState;
    const values = {};
    let rehericalDetails = {};
    if (rehericaltime) {
      rehericalDetails = getModifiedDateTime({
        bookingStartStr: rehericalStartTime?.split('-')[0],
        bookingEndStr: rehericalEndTime?.split('-')[0],
        bookingEndDate: reherical_date,
        bookingStartDate: reherical_date,
      });
    }
    // return
    const rehericalStartDate = new Date(moment(bookingStartDate).add(2, 'days'));
    const rehericalEndDate = new Date(moment(bookingEndDate).add(2, 'days'));
    values['bookingStartDate'] = bookingState?.bookingDatesTimes.at(0)?.bookingStartDate;
    values['bookingEndDate'] = bookingState?.bookingDatesTimes.at(0)?.bookingEndDate;
    values['bookingStartTime'] = bookingState?.bookingDatesTimes.at(0)?.startHours;
    values['bookingEndTime'] = bookingState?.bookingDatesTimes.at(0)?.endHours;
    values['rehericaltime'] = rehericaltime;
    values['reherical_time'] = new Date(rehericalDetails?.bookingStartDate).getTime().toString();
    values['description'] = description;
    values['duration'] = 60;
    values['services'] = services;
    values['totalSelectedDate'] = totalSelectedDate;
    values['reherical_date'] = {
      startDate: rehericalDetails?.bookingStartDate,
      endDate: rehericalDetails?.bookingEndDate,
    };
    values['totalSelectedDatesTimes'] = bookingDatesTimes;
    console.log('props.serviceOffered', props.serviceOffered);
    // values['selectedService'] =
    //   Object.values(props.serviceOffered).length > 0 ? Object.values(props.serviceOffered)[0] : '';
    values['selectedService'] = bookingState?.selectedService;
    console.log('bookingDatesTimesbookingDatesTimesbookingDatesTimes', bookingDatesTimes);
    // let totalSelectedDatesTimes = [];

    // for (let i = 0; i < totalSelectedDate.length; i++) {
    //     let bookingStartDate = moment(totalSelectedDate[i]).add(startHours, 'hours')['_d']
    //     let bookingEndDate = moment(totalSelectedDate[i]).add(endHours, 'hours')['_d']
    //     totalSelectedDatesTimes.push({
    //       bookingStartDateTime: bookingStartDate,
    //       bookingEndDateTime: bookingEndDate,
    //       startHours,
    //       endHours
    //     })

    // }
    // values['totalSelectedDatesTimes'] = totalSelectedDatesTimes
    // values['reherical_date'] = { startDate: bookingStartDate?.bookingStartDate, endDate: bookingEndDate };

    values['description_church'] = description_church;
    values['other_details'] = other_details;
    values['city'] = city;
    values['state'] = state;
    console.log('sssss', values);
    props.handleSubmit(values);
  };

  const addRemoveFav = async () => {
    try {
      addRemoveFavorite(props.currentListing.id);
      setFavorite(!isFavorite);
    } catch (e) {
      console.error(e);
    }
  };

  const selectedServiceHandler = serviceValue => {
    // localStorage.setItem('selectedService', serviceValue);

    setBookingState({ ...bookingState, selectedService: serviceValue });
  };

  const setDescriptionChurch = ({
    value,
    valueDesc,
    valueDesc2,
    servicesValue,
    cityValue,
    cityState,
    zipValue,
  }) => {
    localStorage.setItem(
      props.currentUser?.id.uuid,
      JSON.stringify({
        description_church: value ?? '',
        description: valueDesc ?? '',
        address: valueDesc2 ?? '',
        services: servicesValue ?? '',
        city: cityValue ?? '',
        state: cityState ?? '',
        zip: zipValue ?? '',
      })
    );

    setBookingState({
      ...bookingState,
      description_church: value,
      description: valueDesc,
      address: valueDesc2,
      services: servicesValue,
      city: cityValue,
      state: cityState,
      zip: zipValue,
    });
  };

  const setDescriptionChurchDesc = value => {
    setBookingState({
      ...bookingState,
    });
  };

  const setOtherDetails = detail => {
    setBookingState({ ...bookingState, other_details: detail });
  };

  const selectedDateHandler = dateValue => {
    setBookingState({ ...bookingState, selectedDate: dateValue });
  };

  const requestBookHandler = () => {
    setRequestBook(prevState => !prevState);
  };

  const selectDateHandler = () => {
    setSelectDate(prevState => !prevState);
  };
  const selectedDatesTimesHandler = value => {
    console.log('selectedDatesTimesHandler', value);
    setBookingState({ ...bookingState, bookingDatesTimes: value });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const onChangeCheckbox = e => {
    setBookingState({ ...bookingState, rehericaltime: e.target.checked });
  };

  const bookService = () => {
    if (!requestBook && !selectDate) {
      return (
        <BookService
          requestHandler={requestBookHandler}
          selectDateHandler={selectDateHandler}
          openModal={setModalOpen}
          title={props.title}
          bookingState={bookingState}
          serviceWithPrice={props.serviceWithPrice}
          price={price}
          onContactUser={props.onContactUser}
          timeZone={moment.tz.guess()}
          unitType={props.unitType}
          lineItems={props.lineItems}
        />
      );
    }

    if (requestBook) {
      return (
        <SelectService
          serviceOffered={props.serviceOffered}
          serviceWithPrice={props.serviceWithPrice}
          requestHandler={requestBookHandler}
          selectedServiceHandler={selectedServiceHandler}
          bookingState={bookingState}
          price={price}
        />
      );
    }

    if (selectDate) {
      return (
        <SelectDate
          selectedDateHandler={selectedDateHandler}
          requestHandler={requestBookHandler}
          selectDateHandler={selectDateHandler}
          selectedDatesTimesHandler={selectedDatesTimesHandler}
          bookingState={bookingState}
          monthlyTimeSlots={props.monthlyTimeSlots}
          availableStartTimes={availableStartTimes}
          listingId={props.currentListing.id}
          timeZone={timeZone}
          onFetchTimeSlots={props.onFetchTimeSlots}
          setCurrentMonth={setCurrentMonth}
          currentMonth={currentMonth}
        />
      );
    }
  };

  const musicStyleFn = () => {
    if (props.musicStyles.length) {
      return (
        <div className={css.listBulletContainer}>
          <div className={css.styleContainer}>
            <p className={css.styleTitle}>Music Styles</p>
            {props?.musicStyles.map((music, ind) => {
              return (
                <ListServiceStyle
                  text={capitalizeFirstLetter(music)
                    .replaceAll('_', ' ')
                    .replaceAll('christianc', 'christian')}
                  key={ind}
                />
              );
            })}
          </div>
          {props.travelTo.length > 0 && (
            <div className={css.styleContainer}>
              <p className={css.styleTitle}>Willing to Travel</p>
              {props.travelTo.map((city, ind) => (
                <ListServiceStyle text={city} key={ind} />
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  console.log('bookingState', bookingState);
  return (
    <div>
      <div className={css.serviceOfferedContainer}>
        <Modal
          open={modalOpen}
          // open={true}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={css.modalContentCenter}
        >
          <div className={css.modalContent}>
            <BookingWizard
              handleClose={handleClose}
              bookingState={bookingState}
              serviceWithPrice={props.serviceWithPrice}
              setOtherDetails={setOtherDetails}
              onChangeCheckbox={onChangeCheckbox}
              handleSubmit={handleSubmit}
              setDescriptionChurch={setDescriptionChurch}
              setDescriptionChurchDesc={setDescriptionChurchDesc}
              title={props.title}
              price={price}
              monthlyTimeSlots={props.monthlyTimeSlots}
              availableStartTimes={availableStartTimes}
              setBookingState={setBookingState}
            />
          </div>
        </Modal>
        {/* <div className={css.requestToBook}>
        <p>$60</p>
        <button className={css.button + ' ' + css.bookingButton + ' '}> Request to book</button>
      </div> */}
        <div className={css.serviceListContainer}>
          {props.serviceOffered.length > 0 && (
            <div className={css.serviceList}>
              <p>Service Offered</p>
              <ul className={css.listName}>
                {props.serviceOffered.map((service, ind) => {
                  console.log(service);
                  return (
                    <li>
                      {serviceOfferedObj[service]}
                      <p>
                        {service !== 'pianist'
                          ? capitalizeFirstLetter(service).replaceAll('_', ' ')
                          : 'Keys'}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {/* sdfasf
        <EstimatedBreakdownMaybe
          bookingData={{
            startDate: bookingState?.selectedDate?.bookingStartDate,
            endDate: bookingState?.selectedDate?.bookingEndDate,
            timeZone: timeZone,
            unitType: props.unitType,
            bookingData1: null,
          }}
          lineItems={props.lineItems}
        /> */}
          <div className={css.serviceListDetails}>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}

            <p className={css.desc}>
              {props.description.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')}
            </p>
            {musicStyleFn()}
          </div>
        </div>

        <div className={css.absolutePositionedBooking}>
          <div className={css.shareContainer}>
            <RWebShare
              data={{
                text: 'Worship Serve',
                url: `https://www.worshipserve.com${location.pathname}`,
                title: 'Worship Serve',
              }}
              onClick={() => {
                console.log('shared successfully!');
              }}
            >
              <button className={css.shareBtn}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: 'transparent', marginRight: 10 }}
                >
                  <path
                    d="M16.5 9V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V9M12.3333 4.83333L9 1.5M9 1.5L5.66667 4.83333M9 1.5V11.5"
                    stroke="#6941C6"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Share
              </button>
            </RWebShare>
            {props.isAuthenticated && (
              <button className={css.shareBtn + ' ' + css.favorite} onClick={addRemoveFav}>
                <Heart isFilled={isFavorite} />
                Favorite
              </button>
            )}
          </div>
          <div className={css.bookingContainer}>{bookService()}</div>
        </div>
      </div>
    </div>
  );
};

export default ListingServiceOffered;
